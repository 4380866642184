import React, { useState } from "react"
import axios from "axios"
import "./lifestyle.scss"

const Lifestyle = ({ classNames, pageHref, onSuccess }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    if (isLoading) return

    e.preventDefault()
    const data = {
      firstname: firstName,
      lastname: lastName,
      phonenumber: phoneNumber,
      from: pageHref,
    }

    try {
      setIsLoading(true)
      const res = await axios.post(
        "https://getform.io/f/b384a93c-46e6-49ad-bf4c-f96514f35ffa",
        data
      )
      setIsLoading(false)
      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case "firstName":
        setFirstName(e.target.value)
        break
      case "lastName":
        setLastName(e.target.value)
        break
      case "phoneNumber":
        setPhoneNumber(e.target.value)
        break
      default:
        break
    }
  }

  return (
    <form
      className={`form lifestyle-contact-form ${classNames}`}
      id="lifestyle-contact-form"
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input type="text" name="firstName" required />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input type="text" name="lastName" required />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input type="tel" maxLength={16} name="phoneNumber" required />
      </div>
      <input
        type="hidden"
        name="_gotcha"
        style={{ display: "none !important" }}
      ></input>
      <button disabled={isLoading} type="submit">
        {isLoading ? "Submitting..." : "Submit"}
      </button>
    </form>
  )
}

export default Lifestyle
